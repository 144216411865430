import React from "react";

export default function TextContainer(props) {
  const css_class = props.className ? props.className : ""

  return (
    <div className={"flex px-4 flex-wrap justify-center mx-auto " + css_class} >
      <div className="px-4 md:px-6 w-full max-w-4xl text_container">
        {props.children}
      </div>
    </ div>
  )
}