import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import TextContainer from "../components/text-container";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const query = graphql`
query BlogPageQuery {
  allSanityPost(
    filter: {slug: {current: {ne: "null"}}}
    sort: {fields: publishedAt, order: DESC}
  ) {
    edges {
      node {
        publishedAt
        author {
          image {
            asset {
              gatsbyImageData(width: 60, height: 60)
            }
          }
          name
          slug {
            current
          }
        }
        description
        title
        slug {
          current
        }
        seo {
          description
          title
        }
        mainImage {
          asset {
            gatsbyImageData(width: 1000, height: 250, fit: CROP)
          }
        }
      }
    }
  }
}

`;

export default function BlogPage(props) {
  const posts = props.data?.allSanityPost?.edges ?? [];
  return (
    <Layout title="Blog" description="Need to learn more about ERISA?  Look no further." location="/blog">
      <TextContainer>
        <h1 className="text-center pb-8">Blog</h1>
        {posts.map(({ node }) => (
          <div key={node._id} className="border-b pb-6 mb-8">
            <Link to={`/blog/${node.slug.current}`} className="group">
              <GatsbyImage className="rounded-lg" image={getImage(node.mainImage.asset)} alt={node.title} />
              <h2 className="mt-4 group-hover:text-secondary transition-all">{node.title}</h2>
            </Link>
            <p>{node.description}</p>
            <div className="mt-6 flex items-center">
              <div className="flex-shrink-0">
                <a href={node.author.href}>
                  <span className="sr-only">{node.author.name}</span>
                  <GatsbyImage className="w-10 h-10 rounded-full" image={getImage(node.author.image.asset)} alt={node.author.name} />
                </a>
              </div>
              <div className="ml-3">
                <span className="text-sm font-medium text-gray-900">
                  <a href={"/attorneys/" + node.author.slug.current} className="hover:underline unset">
                    {node.author.name}
                  </a>
                </span>
                <div className="flex space-x-1 text-sm text-gray-500">
                  <time dateTime={node.publishedAt}>{new Date(node.publishedAt).toLocaleDateString('en-US')}</time>
                  {/* <span aria-hidden="true">&middot;</span>
                      <span>{node.readingTime} read</span> */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </TextContainer>
    </Layout>
  );
}